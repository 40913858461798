import SecuredConnection from "@/helpers/general/SecuredConnection";

export default async function GetOwnData(organization, conference, userid) {
  let GetStatus = {
    success: true,
    errorid: null,
  };

  // Check fields

  if (!organization) {
    GetStatus.success = false;
    GetStatus.errorid = "getsingleuser_1";
    return GetStatus;
  }

  const userApiUrl =
    process.env.VUE_APP_API_GATEWAY +
    process.env.VUE_APP_API_USER +
    "public/public_all_users/" +
    organization +
    "/" +
    conference +
    "/" +
    userid;

  const bearer = "Bearer " + localStorage.getItem("auth/jwt");

  const requestObject = {
    method: "GET",
    mode: "cors",
    credentials: "include",
    headers: {
      Authorization: bearer,
    },
  };

  const response = await SecuredConnection(userApiUrl, requestObject);

  if (!response) {
    GetStatus.success = false;
    GetStatus.errorId = "getallusers_2";
    return GetStatus;
  } else if (response.status === 200) {
    const returnObject = await response.json();
    if (returnObject.success === true) {
      return returnObject;
    } else {
      GetStatus.success = false;
      GetStatus.errorid = returnObject.errorid;
      return GetStatus;
    }
  } else {
    const returnObject = await response.json();
    GetStatus.success = false;
    GetStatus.errorid = returnObject.errorid;
    return GetStatus;
  }
}
