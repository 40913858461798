import { io } from "socket.io-client";

let URL;

URL = process.env.VUE_APP_API_GATEWAY;

let socket = io(URL, { path: process.env.VUE_APP_API_TRACKING, autoConnect: false, withCredentials: true, transports: ['websocket'] });

socket.on("connect_error", (err) => {
  console.warn(err)
});

socket.on('error', function (err) {
  console.error(err);
});

export default socket;