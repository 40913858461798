<template>
  <div :class="['post-list', { 'pb-4': posts.length > visiblePosts.length }]">
    <template v-for="post in visiblePosts" :key="post.id">
      <div
        class="post"
        :class="{
          'has-children': post.children,
          'pb-4': post.children && !post.showChildren,
        }"
      >
        <PostHeader :post="post" :translation="translation" />
        <div class="post-content ml-5">
          <div class="ml-3">
            <p v-html="post.sanitizedContent"></p>
          </div>
          <ForumElementFooter
            :forumElement="post"
            :translation="translation"
            :isPreview="true"
            :allowedActions="forum.permissions"
            @reload="reloadSection(post)"
          />
        </div>
        <div
          v-if="post.children && !post.showChildren"
          class="more-button is-clickable"
          @click="post.showChildren = true"
        >
          <span><i class="mdi mdi-plus-circle-outline" /></span>
        </div>
        <div
          v-if="post.children && post.showChildren"
          class="ml-5 post-children"
        >
          <PostList
            :forum="forum"
            :posts="post.children"
            :translation="translation"
            :showAll="post.showChildren"
            @reload="reloadSection(post)"
          />
        </div>
      </div>
    </template>
    <div
      v-if="posts.length > visiblePosts.length"
      class="more-button is-clickable"
      @click="visiblePosts = posts"
    >
      <span><i class="mdi mdi-plus-circle-outline" /></span>
    </div>
  </div>
</template>

<script setup>
import { defineProps, defineEmits, watchEffect, ref } from "vue";

import GetPostsByParent from "@/helpers/community/post/getByParent";

import { getProfilePicture } from "@/helpers/userData/functions/GetProfilePicture.js";
import GetOwnData from "@/helpers/userData/external_data_public_all.js";

import moment from "moment-timezone";

import DOMPurify from "dompurify";

import PostHeader from "@/components/forum/buildingBlocks/postHeader.vue";
import ForumElementFooter from "@/components/forum/buildingBlocks/forumElementFooter.vue";

const emit = defineEmits(["reload"]);

const props = defineProps({
  forum: Object,
  posts: Array,
  translation: Object,
  showAll: Boolean,
});

watchEffect(() => {
  moment.locale(props.translation.moment_locale);
});

const visiblePosts = ref([]);

watchEffect(() => {
  if (!props.showAll) {
    visiblePosts.value = props.posts.slice(0, 2);
  } else {
    visiblePosts.value = props.posts;
  }
});

async function reloadSection(post) {
  const posts_response = await GetPostsByParent(
    post.organization_id,
    post.forum_id,
    post.thread_id,
    post.id
  );

  if (posts_response.success) {
    if (
      posts_response.returns.data.length > 0 &&
      posts_response.returns.data[0].children
    ) {
      posts_response.returns.data[0].children.forEach((element) => {
        element.author = {};
      });
      post.children = posts_response.returns.data[0].children;

      addAuthorNested(post.children);
      formatDatesNested(post.children);
      sanitizeContentNested(post.children);

      post.showChildren = true;
    }
  } else {
    emit("reload");
  }
}

function sanitizeContentNested(posts) {
  for (let post of posts) {
    sanitizeContent(post);

    if (post.children) {
      sanitizeContentNested(post.children);
    }
  }
}

function sanitizeContent(threadOrPost) {
  if (threadOrPost.name) {
    threadOrPost.sanitizedName = DOMPurify.sanitize(threadOrPost.name, {
      USE_PROFILES: { html: true },
    });
    delete threadOrPost.name; // Delete so no one is tempted to use unsanitized data
  }

  if (threadOrPost.content) {
    threadOrPost.sanitizedContent = DOMPurify.sanitize(threadOrPost.content, {
      USE_PROFILES: { html: true },
    });
    delete threadOrPost.content; // Delete so no one is tempted to use unsanitized data
  }
}

function formatDatesNested(posts) {
  for (let post of posts) {
    formatDates(post);

    if (post.children) {
      formatDatesNested(post.children);
    }
  }
}

function formatDates(threadOrPost) {
  let creationDate = moment(threadOrPost.creation_date);

  threadOrPost.formatted_creation_date = creationDate.calendar(
    props.translation.moment_calendar
  );

  if (threadOrPost.modified_date) {
    let modificationDate = moment(threadOrPost.modified_date);
    threadOrPost.formatted_modified_date = modificationDate.calendar(
      props.translation.moment_calendar
    );
  }
}

const users = ref([]);

async function addAuthorNested(posts) {
  for (let post of posts) {
    addAuthor(post);

    if (post.children) {
      addAuthorNested(post.children);
    }
  }
}

async function addAuthor(threadOrPost) {
  let user = users.value.find((element) => {
    return element.user_id === threadOrPost.author_id;
  });

  // Get user data if not loaded yet
  if (!user) {
    let userData = await GetOwnData(
      threadOrPost.organization_id,
      null,
      threadOrPost.author_id
    );

    if (userData.success) {
      let imageURL = getProfilePicture(
        threadOrPost.organization_id,
        threadOrPost.author_id
      );
      userData.returns.data.imageURL = imageURL;
      users.value.push(userData.returns.data);
    }
    user = users.value.find((element) => {
      return element.user_id === threadOrPost.author_id;
    });
  }

  threadOrPost.author = user;
  setImageExists(threadOrPost);
}

async function setImageExists(threadOrPost) {
  try {
    const image_response = await addImageProcess(threadOrPost.author.imageURL);
    if (image_response) {
      threadOrPost.author.hasImage = true;
    } else {
      threadOrPost.author.hasImage = false;
    }
  } catch (e) {
    console.log("unable to load picture for " + threadOrPost.author.user_id);
  }
}

function addImageProcess(src) {
  return new Promise((resolve, reject) => {
    let img = new Image();
    img.onload = () => resolve(img.width);
    img.onerror = () => reject(false);
    img.src = src;
  });
}
</script>

<style scoped>
.post-list,
.post-list .post,
.post.has-children header,
.post.has-children .post-content {
  position: relative;
}

/******* LINES *******/
.post-children > .post-list > .post > header::before {
  z-index: 1;
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: -0.4em;
  width: 1.4em;
  height: 50%;
  border-left: 1px solid var(--main-color);
  border-bottom: 1px solid var(--main-color);
  border-bottom-left-radius: 50%;
}

.post.has-children > .post-content::before {
  z-index: 1;
  content: "";
  display: block;
  position: absolute;
  left: -0.3em;
  height: 100%;
  border-left: 1px solid var(--main-color);
}

.has-children .post-list .post::after {
  z-index: 1;
  content: "";
  display: block;
  position: absolute;
  left: -0.3em;
  top: 0;
  height: 100%;
  border-left: 1px solid var(--main-color);
}

.has-children .post-list .post:last-child::after {
  display: none;
}

/******* MORE BUTTONS *******/
.post-list .more-button {
  z-index: 2;
  position: absolute;
  bottom: 0;
  left: 0.75em;
}
.post-list > .more-button {
  left: -0.75em;
}
</style>
