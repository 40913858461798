<template>
  <template v-if="forum && thread">
    <div class="card">
      <ThreadHeader
        :forum="forum"
        :thread="thread"
        :translation="translation"
        @go-to-forum="goToForum"
      />
      <div class="m-3 content">
        <!-- <div class="card-image"></div> -->
        <div>
          <p v-html="thread.sanitizedContent"></p>
        </div>
      </div>

      <ForumElementFooter
        :forumElement="thread"
        :translation="translation"
        :allowedActions="forum.permissions"
        @reload="loadData()"
      />
    </div>
    <PostList
      v-if="posts.length > 0"
      :forum="forum"
      :posts="posts"
      :translation="translation"
      :showAll="true"
      @reload="loadData()"
    />
    <div class="no-posts m-5 has-text-centered" v-else>
      {{ translation.overview.no_posts }}
    </div>
  </template>
</template>

<script setup>
import { ref, watchEffect, defineProps, defineEmits } from "vue";

import GetThreadById from "@/helpers/community/thread/getById";
import GetPostsByThread from "@/helpers/community/post/getByThread";

import { getProfilePicture } from "@/helpers/userData/functions/GetProfilePicture.js";
import GetOwnData from "@/helpers/userData/external_data_public_all.js";

import moment from "moment-timezone";

import DOMPurify from "dompurify";

import ThreadHeader from "@/components/forum/buildingBlocks/threadHeader.vue";
import ForumElementFooter from "@/components/forum/buildingBlocks/forumElementFooter.vue";
import PostList from "@/components/forum/postList.vue";

const emit = defineEmits(["go-to-forum"]);

const props = defineProps({
  organization: Object,
  forum: Object,
  threadId: String,
  translation: Object,
  authObject: Object,
});

/**
 * DATA
 */

let organization;
if (localStorage.getItem("organization/metadata")) {
  organization = JSON.parse(localStorage.getItem("organization/metadata"));
}

watchEffect(() => {
  moment.locale(props.translation.moment_locale);
});

// Load threads
const thread = ref();
const posts = ref([]);
const users = ref([]);

watchEffect(async () => {
  if (props.forum) {
    loadData();
  }
});

async function loadData() {
  posts.value = [];

  // Get threads for this forum
  const thread_result = await GetThreadById(
    organization.id,
    props.forum.id,
    props.threadId
  );

  // Handle errors (like permissions etc)
  if (!thread_result.success) {
    // TODO Handle errors
  } else {
    thread_result.returns.data.author = {};
    thread.value = thread_result.returns.data;

    addAuthor(thread.value);
    sanitizeContent(thread.value);

    const post_result = await GetPostsByThread(
      organization.id,
      props.forum.id,
      thread.value.id
    );

    if (post_result.success) {
      post_result.returns.data.forEach((element) => {
        element.author = {};
      });

      // Add image URLs and load images to replace placeholders
      // loadImages();
      posts.value = post_result.returns.data;
      addAuthorNested(posts.value);
      sanitizeContentNested(posts.value);
    }

    // Format dates
    formatDates(thread.value);
    formatDatesNested(posts.value);
  }
}

/**
 * HELPERS
 */

function formatDatesNested(posts) {
  for (let post of posts) {
    formatDates(post);

    if (post.children) {
      formatDatesNested(post.children);
    }
  }
}

function formatDates(threadOrPost) {
  let creationDate = moment(threadOrPost.creation_date);

  threadOrPost.formatted_creation_date = creationDate.calendar(
    props.translation.moment_calendar
  );

  if (threadOrPost.modified_date) {
    let modificationDate = moment(threadOrPost.modified_date);
    threadOrPost.formatted_modified_date = modificationDate.calendar(
      props.translation.moment_calendar
    );
  }
}

async function addAuthorNested(posts) {
  for (let post of posts) {
    addAuthor(post);

    if (post.children) {
      addAuthorNested(post.children);
    }
  }
}

async function addAuthor(threadOrPost) {
  let user = users.value.find((element) => {
    return element.user_id === threadOrPost.author_id;
  });

  // Get user data if not loaded yet
  if (!user) {
    let userData = await GetOwnData(
      organization.id,
      null,
      threadOrPost.author_id
    );

    if (userData.success) {
      let imageURL = getProfilePicture(organization.id, threadOrPost.author_id);
      userData.returns.data.imageURL = imageURL;
      users.value.push(userData.returns.data);
    }
    user = users.value.find((element) => {
      return element.user_id === threadOrPost.author_id;
    });
  }

  threadOrPost.author = user;
  setImageExists(threadOrPost);
}

function sanitizeContentNested(posts) {
  for (let post of posts) {
    sanitizeContent(post);

    if (post.children) {
      sanitizeContentNested(post.children);
    }
  }
}

function sanitizeContent(threadOrPost) {
  if (threadOrPost.name) {
    threadOrPost.sanitizedName = DOMPurify.sanitize(threadOrPost.name, {
      USE_PROFILES: { html: true },
    });
    delete threadOrPost.name; // Delete so no one is tempted to use unsanitized data
  }

  if (threadOrPost.content) {
    threadOrPost.sanitizedContent = DOMPurify.sanitize(threadOrPost.content, {
      USE_PROFILES: { html: true },
    });
    delete threadOrPost.content; // Delete so no one is tempted to use unsanitized data
  }
}

async function setImageExists(threadOrPost) {
  try {
    const image_response = await addImageProcess(threadOrPost.author.imageURL);
    if (image_response) {
      threadOrPost.author.hasImage = true;
    } else {
      threadOrPost.author.hasImage = false;
    }
  } catch (e) {
    console.log("unable to load picture for " + threadOrPost.author.user_id);
  }
}

function addImageProcess(src) {
  return new Promise((resolve, reject) => {
    let img = new Image();
    img.onload = () => resolve(img.width);
    img.onerror = () => reject(false);
    img.src = src;
  });
}

/**
 * ROUTER HANDLING
 */
function goToForum(shortlink) {
  emit("go-to-forum", shortlink);
}
</script>

<style scoped>
.dynpage-main-color .card:hover {
  background-color: transparent;
}
</style>
