import { io } from "socket.io-client";

let URL;

URL = process.env.VUE_APP_API_GATEWAY;

let socket = io(URL, { path: process.env.VUE_APP_API_CHAT, autoConnect: false, withCredentials: true, transports: ['websocket'] });

socket.on("connect_error", (err) => {
  console.warn(err)
});

export default socket;