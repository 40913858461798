export default function RefreshTokenCall(
  authentication_api_url,
  requestObject
) {
  return new Promise((resolve) => {
    fetch(authentication_api_url, requestObject)
      .then(async (response) => {
        if (!response) {
          resolve(false);
        } else if (response.status === 200) {
          const returnObject = await response.json();
          await localStorage.setItem("auth/jwt", returnObject.token);
          resolve(returnObject.token);
        } else {
          resolve(false);
        }
      })
      .catch((e) => {
        console.error(e);
        resolve(false);
      });
  });
}
