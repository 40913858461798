<template>
  <div v-if="loading" class="eventos-lock-screen">
    <i class="mdi mdi-loading icn-spinner"></i>
  </div>
  <div v-if="!loading">
    <div class="card" v-for="thread in threads" :key="thread.id">
      <a
        class="go-to-thread"
        @click="
          goToThread(
            forums.find((element) => {
              return element.id === thread.forum_id;
            }).shortlink,
            thread.id
          )
        "
      ></a>
      <ThreadHeader
        :forum="
          forums.find((element) => {
            return element.id === thread.forum_id;
          })
        "
        :thread="thread"
        :translation="translation"
        @go-to-forum="goToForum"
      />
      <div class="m-3 content">
        <!-- <div class="card-image"></div> -->
        <div class="thread-preview">
          <p v-html="thread.sanitizedContent"></p>
        </div>
      </div>
      <ForumElementFooter
        :forumElement="thread"
        :translation="translation"
        :allowedActions="[]"
        :isPreview="true"
      />
    </div>
  </div>
</template>

<script setup>
import { ref, watchEffect, defineProps, defineEmits } from "vue";

import GetThreadsByForum from "@/helpers/community/thread/getByForum";

import { getProfilePicture } from "@/helpers/userData/functions/GetProfilePicture.js";
import GetOwnData from "@/helpers/userData/external_data_public_all.js";

import moment from "moment-timezone";

import * as DOMPurify from "dompurify";

import ThreadHeader from "@/components/forum/buildingBlocks/threadHeader.vue";
import ForumElementFooter from "@/components/forum/buildingBlocks/forumElementFooter.vue";

const emit = defineEmits(["go-to-forum", "go-to-thread"]);

const props = defineProps({
  organization: Object,
  forums: Array,
  translation: Object,
});

/**
 * DATA
 */

watchEffect(() => {
  moment.locale(props.translation.moment_locale);
});

const loading = ref(true);
const threads = ref([]);
const users = ref([]);

watchEffect(async () => {
  let tmp_threads = [];
  loading.value = true;

  if (props.forums.length !== 0) {
    threads.value = [];

    for (let forum of props.forums) {
      // Get threads for this forum
      const thread_result = await GetThreadsByForum(
        props.organization.id,
        forum.id
      );

      // Handle errors (like permissions etc)
      if (!thread_result.success) {
        // TODO Handle errors
      } else {
        thread_result.returns.data.forEach((element) => {
          element.author = {};
        });
        tmp_threads.push(...thread_result.returns.data);
      }
    }

    tmp_threads = tmp_threads.sort((a, b) => {
      return new Date(b.creation_date) - new Date(a.creation_date);
    });

    // Format dates
    for (let thread of tmp_threads) {
      let creationDate = moment(thread.creation_date);
      let modificationDate = moment(thread.modified_date);

      thread.formatted_creation_date = creationDate.calendar(
        props.translation.moment_calendar
      );

      if (thread.modified_date) {
        thread.formatted_modified_date = modificationDate.calendar(
          props.translation.moment_calendar
        );
      }
    }

    threads.value = tmp_threads;

    if (threads.value.length > 0) {
      sanitizeContent();

      // Add image URLs and load images to replace placeholders
      loadImages();
    }
  }

  loading.value = false;
});

async function loadImages() {
  for (let thread of threads.value) {
    let user = users.value.find((element) => {
      return element.user_id === thread.author_id;
    });

    // Get user data if not loaded yet
    if (!user) {
      let userData = await GetOwnData(
        props.organization.id,
        null,
        thread.author_id
      );
      if (userData.success) {
        let imageURL = getProfilePicture(
          props.organization.id,
          thread.author_id
        );
        userData.returns.data.imageURL = imageURL;
        users.value.push(userData.returns.data);
      }
      user = users.value.find((element) => {
        return element.user_id === thread.author_id;
      });
    }

    thread.author = user;
  }

  setImageExists();
}

function sanitizeContent() {
  for (let thread of threads.value) {
    if (thread.name) {
      thread.sanitizedName = DOMPurify.sanitize(thread.name, {
        USE_PROFILES: { html: true },
      });
      delete thread.name; // Delete so no one is tempted to use unsanitized data
    }

    if (thread.content) {
      thread.sanitizedContent = DOMPurify.sanitize(thread.content, {
        USE_PROFILES: { html: true },
      });
      delete thread.content; // Delete so no one is tempted to use unsanitized data
    }
  }
}

/**
 * HELPERS
 */

async function setImageExists() {
  for (let thread of threads.value) {
    try {
      const image_response = await addImageProcess(thread.author.imageURL);
      if (image_response) {
        thread.author.hasImage = true;
      } else {
        thread.author.hasImage = false;
      }
    } catch (e) {
      console.log("unable to load picture for " + thread.author.user_id);
    }
  }
}

function addImageProcess(src) {
  return new Promise((resolve, reject) => {
    let img = new Image();
    img.onload = () => resolve(img.width);
    img.onerror = () => reject(false);
    img.src = src;
  });
}

/**
 * ROUTER HANDLING
 */
function goToForum(shortlink) {
  emit("go-to-forum", shortlink);
}

function goToThread(shortlink, id) {
  emit("go-to-thread", shortlink, id);
}
</script>
