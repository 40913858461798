import RefreshTokenCall from "./RefreshTokenCall";

let refreshTokenPromise;

export default function RefreshToken() {
  // Prepare data for login call to server

  const authentication_api_url =
    process.env.VUE_APP_API_GATEWAY +
    process.env.VUE_APP_API_AUTHENTICATION +
    "refresh";

  const bearer = "Bearer " + localStorage.getItem("auth/jwt");

  const requestObject = {
    method: "POST",
    mode: "cors",
    credentials: "include",
    headers: {
      Authorization: bearer,
      "Content-Type": "application/json",
    },
  };

  if (!refreshTokenPromise) {
    refreshTokenPromise = RefreshTokenCall(
      authentication_api_url,
      requestObject
    );
  }

  setTimeout(() => {
    refreshTokenPromise = null;
  }, 10000);

  return refreshTokenPromise;
}
