<template>
  <footer class="card-footer">
    <div class="card-footer-item just-con-start">
      <template v-if="isPreview">
        <span v-if="forumElement.view_count && false" class="pr-3"
          ><i class="mdi mdi-eye-outline mr-1" />{{
            forumElement.view_count
          }}</span
        >
        <span v-if="forumElement.reactions" class="pr-3"
          ><i class="mdi mdi-thumb-up-outline mr-1" />123</span
        >
        <span v-if="forumElement.post_count" class="pr-3"
          ><i class="mdi mdi-message-outline mr-1" />{{
            forumElement.post_count
          }}</span
        >
        <span
          v-if="actions.respond"
          class="pr-3 is-clickable"
          @click="togglePosting()"
          ><i class="mdi mdi-message-plus-outline"
        /></span>
      </template>
      <template v-else>
        <button
          v-if="forumElement.view_count && false"
          class="button is-main m-1"
          disabled
        >
          <i class="mdi mdi-eye-outline mr-2" />
          {{ forumElement.view_count }}
        </button>
        <button
          v-if="forumElement.reactions"
          class="button is-main m-1"
          disabled
        >
          <i class="mdi mdi-thumb-up-outline mr-2" />
          123
        </button>
        <button
          v-if="actions.respond"
          class="button is-main m-1"
          @click="togglePosting()"
        >
          <i class="mdi mdi-message-plus-outline mr-2" />
          {{ forumElement.post_count }}
        </button>
        <button
          v-if="actions.report"
          class="button is-main m-1"
          @click="respondTo()"
        >
          <i class="mdi mdi-flag-variant-outline mr-2" />
          {{ translation.overview.buttons.report_post }}
        </button>
        <button
          v-if="actions.share"
          class="button is-main m-1"
          @click="respondTo()"
        >
          <i class="mdi mdi-share-variant mr-2" />
          {{ translation.overview.buttons.share_post }}
        </button>
      </template>
    </div>
    <div v-if="isPreview" class="card-footer-item just-con-end">
      <span v-if="actions.report" class="pl-3"
        ><i class="mdi mdi-flag-variant-outline mr-1"
      /></span>
      <span v-if="actions.share" class="pl-3"
        ><i class="mdi mdi-share-variant mr-1"
      /></span>
    </div>
  </footer>
  <template v-if="posting">
    <div class="field mt-2" style="line-height: 15px">
      <editor
        api-key="yp0m3cpsawjia95l13xuhnel1zym1gw3ykqj5ptfhh5fh53q"
        :init="{
          height: 250,
          menubar: false,
          plugins: [
            'advlist',
            'autolink',
            'lists',
            'link',
            'image',
            'charmap',
            'print',
            'preview',
            'anchor',
            'searchreplace',
            'visualblocks',
            'code',
            'fullscreen',
            'emoticons',
            'insertdatetime',
            'media',
            'table',
            'paste',
            'code',
            'help',
            'wordcount',
          ],
          toolbar:
            'undo redo | bold italic underline strikethrough | \
bullist numlist outdent indent ',
          content_style:
            'div {font-family: Arial, sans-serif} p {margin: 0px; padding: 0px} h1 {font-size: 2em; font-weight: 50} p {margin-block-start: 0em; margin-block-end: 0em;}',
        }"
        v-model="newPostContent"
        class="input"
      />
    </div>

    <div class="enter-button has-text-right m-2">
      <button
        class="button is-main"
        @click="respondTo()"
        :class="{ 'is-loading': loading }"
      >
        <i class="mdi mdi-send mr-2" />
        {{ translation.create_thread.buttons.create }}
      </button>
    </div></template
  >
</template>

<script setup>
import Editor from "@tinymce/tinymce-vue";
import { defineProps, defineEmits, ref, computed } from "vue";

import CreatePost from "@/helpers/community/post/create";

const emit = defineEmits(["reload"]);

const props = defineProps({
  forumElement: Object,
  translation: Object,
  isPreview: Boolean,
  allowedActions: Array,
});

const actions = computed(() => {
  let returnObject = {
    respond: false,
    share: false,
    report: false,
  };

  if (props.allowedActions) {
    if (props.forumElement.thread_id) {
      // is post
      returnObject.respond = props.allowedActions.includes("create_posts");
    } else {
      returnObject.respond = props.allowedActions.includes("create_threads");
    }

    returnObject.share = false;
    returnObject.report = false;
  }

  return returnObject;
});

const newPostContent = ref("");
const posting = ref(false);
const loading = ref(false);

function togglePosting() {
  posting.value = !posting.value;

  if (!posting.value) {
    clearValues();
  }
}

async function respondTo() {
  let threadId = null;
  let parentPost = null;

  // Add parent post if parent is of type post
  if (props.forumElement.thread_id) {
    threadId = props.forumElement.thread_id;
    parentPost = props.forumElement.id;
  } else {
    threadId = props.forumElement.id;
  }

  const post_response = await CreatePost(
    props.forumElement.organization_id,
    props.forumElement.forum_id,
    threadId,
    parentPost,
    { content: newPostContent.value }
  );

  if (post_response.success) {
    clearValues();
    emit("reload");
  } else {
    // TODO Error Handling
  }
}

function clearValues() {
  newPostContent.value = "";
  posting.value = false;
}
</script>
