<template>
  <div>
    <router-view />
  </div>
</template>

<script>
export default {
  name: "App",
  components: {},
  setup() {
    return {};
  },
};
</script>

<style>
#app {
  margin: 0;
  font-family: Arial, sans-serif;
}

.tippy-box {
  font-family: Arial, sans-serif;
}

html,
body {
  margin: 0;
  overflow: hidden;
  height: 100%;
}

p {
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 100%;
  font-weight: normal;
  margin: 0;
}

button,
input,
select,
textarea {
  margin: 0;
}

html {
  box-sizing: border-box;
}

*,
:before,
:after {
  box-sizing: inherit;
}

img,
video {
  height: auto;
  max-width: 100%;
}

iframe {
  border: 0;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}
</style>
