<template>
  <div
    class="dynpage-main-color"
    :style="{
      'min-height': remainingViewportHeight,
    }"
  >
    <div>
      <div class="eventos-content">
        <!-- Forum List -->
        <div class="limit-width forum-page pt-3">
          <ForumSidebar
            :organization="organization"
            :height="remainingViewportHeight"
            :translation="translation"
            @go-to-forum="goToForum"
          />

          <div
            class="forum-content p-3"
            :style="{
              height: remainingViewportHeight,
            }"
          >
            <NewThread
              v-if="!isThreadView"
              :organization="organization"
              :forums="forums"
              :translation="translation"
              :authObject="authObject"
              @reload="loadData"
            />
            <div class="content-list">
              <SingleThread
                v-if="isThreadView"
                :organization="organization"
                :forum="forums[0]"
                :threadId="params.thread"
                :translation="translation"
                @go-to-forum="goToForum"
              />
              <ThreadList
                v-else
                :organization="organization"
                :forums="forums"
                :translation="translation"
                @go-to-forum="goToForum"
                @go-to-thread="goToThread"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, watchEffect, defineProps, inject } from "vue";
import router from "@/router";

import GetForumByShortlink from "@/helpers/community/forum/getByShortlink";
import GetAllForums from "@/helpers/community/forum/getAll";

import moment from "moment-timezone";
moment.locale(Intl.NumberFormat().resolvedOptions().locale);

import ForumSidebar from "@/components/forum/forumSidebar.vue";
import NewThread from "@/components/forum/newThread.vue";
import ThreadList from "@/components/forum/threadList.vue";
import SingleThread from "@/components/forum/singleThread.vue";

const props = defineProps({
  organizationId: String,
  params: Object,
  isLoggedIn: Boolean,
  pageLogic: Object,
  authObject: Object,
});

// Prevent forums being called on event level
watchEffect(() => {
  if (props.params.conferenceShortCode !== "overview") {
    localStorage.removeItem("conference/metadata");
    router.push(
      "/" +
        props.params.organizationShortCode +
        "/" +
        props.params.conferenceShortCode
    );
  }
});

const translation_en = inject("translation_en").community.forum;
const translation_translated = inject("translation").community.forum;

let translation = ref(translation_en);

watchEffect(() => {
  if (props.pageLogic) {
    if (props.pageLogic.general && props.pageLogic.general.useTranslation) {
      translation.value = translation_translated;
    } else {
      translation.value = translation_en;
    }
  }
});

/**
 * DATA
 */

let organization;
if (localStorage.getItem("organization/metadata")) {
  organization = JSON.parse(localStorage.getItem("organization/metadata"));
}

const remainingViewportHeight = ref();

watchEffect(() => {
  if (props.pageLogic && props.pageLogic.header) {
    if (props.pageLogic.header.show && props.pageLogic.header.height) {
      remainingViewportHeight.value =
        "calc(100vh - " + props.pageLogic.header.height + "px)";
    } else {
      if (props.pageLogic.header.show) {
        remainingViewportHeight.value = "calc(100vh - 80px)";
      } else {
        remainingViewportHeight.value = "100vh";
      }
    }
  } else {
    remainingViewportHeight.value = "100vh";
  }
});

// Load forums
const forums = ref([]);
const isThreadView = ref(false);

watchEffect(async () => {
  if (props.params) {
    loadData();
  }
});

async function loadData() {
  isThreadView.value = false;
  if (props.params.forum) {
    if (props.params.thread) {
      isThreadView.value = true;
    }
    // Get forum (we need the id)
    const forum_result = await GetForumByShortlink(
      organization.id,
      props.params.forum
    );

    if (!forum_result.success) {
      // TODO Handle errors

      forums.value = [];
    } else {
      const forum = forum_result.returns.data;
      forums.value = [forum];
    }
  } else if (props.params.organizationShortCode) {
    const forum_response = await GetAllForums(organization.id);

    if (!forum_response.success) {
      forums.value = [];
    } else if (forum_response.success) {
      forums.value = forum_response.returns.data;
    }
  }
}

/**
 * ROUTER HANDLING
 */
function goToForum(shortlink) {
  router.push("/" + organization.shortlink + "/overview/forum/" + shortlink);
}

function goToThread(shortlink, id) {
  router.push(
    "/" + organization.shortlink + "/overview/forum/" + shortlink + "/" + id
  );
}
</script>

<style>
/* General */
.dynpage-main-color {
  background-color: var(--main-color);
  color: var(--main-font-color);
}

.dynpage-main-color a {
  color: var(--main-font-color);
}

.dynpage-main-color a:hover {
  color: var(--main-link-color);
}

.dynpage-main-color .forum-navbar a:hover {
  color: var(--main-font-color);
  background-color: var(--main-color);
}

.dynpage-main-color .card:hover {
  background-color: var(--main-color);
}

.eventos-content .limit-width {
  max-width: 1228px;
  margin: 0 auto;
}

/* Layout */
.forum-page {
  display: grid;
  grid-template-columns: 67% 33%;
  grid-template-areas: "content navbar";
  gap: 1.5rem;
}

.forum-page > .forum-navbar {
  grid-area: navbar;
}

.forum-page > * {
  background-color: #ffffff55;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}

.forum-content {
  grid-area: content;
  overflow: auto;
}

header.forum-element-header {
  display: flex;
  /* background-color: transparent; */
  align-items: center;
  gap: 0.5rem;
}

.card .go-to-thread {
  position: absolute;
  inset: 0;
  z-index: 1;
}

.card a:not(.go-to-thread) {
  z-index: 2;
}

.thread-preview {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 7;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.just-con-start {
  justify-content: start;
}

.just-con-end {
  justify-content: end;
}

/* Profile Pictures */
.user-row {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.forum-content .user-image {
  overflow: hidden;
  height: 3rem;
  width: 3rem;
  justify-self: center;
}

.content-list .user-image {
  height: 1rem;
  width: 1rem;
}

.forum-content .user-image > img {
  max-height: 100%;
  max-width: 100%;
  object-fit: cover;
  border-radius: 50%;
}

/* REMOVE WHEN MERGED */
.dynpage-main-color .placeholder-image {
  background-color: var(--main-highlight-color);
}

.forum-content .user-image .centered_text_img {
  font-size: 0.5em;
}

.forum-content .user-image.placeholder-image {
  display: flex;
  justify-content: center;
  align-items: center;
}

.forum-navbar {
  overflow-x: auto;
}

.forum-navbar::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

/*** Overwrite Bulma styles ***/

.content-list .card {
  background-color: transparent;
  color: var(--main-font-color) !important;
  position: relative;
}

.content-list .card-footer,
.content-list .card-footer .card-footer-item {
  border: none;
}

.content-list .media-left {
  margin-right: 0.5rem;
}
</style>

<!-- Scrollbar styles -->
<style scoped>
/*** Scrollbar ***/

/*** MAIN COLOR ***/

/* Track */
.dynpage-main-color .forum-navbar::-webkit-scrollbar-track {
  background: var(--main-font-color);
}

/* Handle */
.dynpage-main-color .forum-navbar::-webkit-scrollbar-thumb {
  background: var(--main-highlight-color);
}

.dynpage-main-color .forum-navbar::-webkit-scrollbar-thumb:hover {
  background: var(--main-link-color);
}
</style>
