import { createApp } from "vue";
import Toast from "vue-toastification";
import VueTippy from "vue-tippy";
import { setDefaultProps } from "vue-tippy";
import { right } from "@popperjs/core";

//import 'bulma/css/bulma.css'
import "@/assets/main.scss";
import "@/assets/roboto.css";
import "@/assets/prometo.css";

import "@mdi/font/css/materialdesignicons.min.css";
import "vue-toastification/dist/index.css";
import "tippy.js/dist/tippy.css";
import "tippy.js/themes/light-border.css";

import * as Sentry from "@sentry/vue";

import App from "./App.vue";
import router from "./router";
import socketTracking from "./socket/tracking";
import socketChat from "./socket/chat";

import initializeTranslation from "@/data/translations/translation";


import PrimeVue from 'primevue/config';

let lang = "en";

if (navigator.language || navigator.userLanguage) {
  lang = navigator.language || navigator.userLanguage;
  lang = lang.substr(0, 2);
}

const translation = initializeTranslation(lang);

const translation_en = initializeTranslation("en");
const translation_de = initializeTranslation("de");

const app = createApp(App);

Sentry.init({
  app,
  dsn: process.env.VUE_APP_SENTRY_DSN,
  environment: process.env.VUE_APP_SENTRY_ENV,
  release: "enduser-panel@" + process.env.VUE_APP_RELEASE,

  replaysSessionSampleRate: 0.1, // Track 10% of all interactions
  replaysOnErrorSampleRate: 1.0, // Track each session with an error for better debugging

  integrations: [new Sentry.Replay()],

});

/*
* PrimeVue Imports START
*/

app.use(PrimeVue);
import 'primeflex/primeflex.css';
import 'primevue/resources/themes/mdc-light-indigo/theme.css';
import 'primeicons/primeicons.css'

import Galleria from 'primevue/galleria';
app.component('PrimeGalleria', Galleria);
/*
* PrimeVue Imports END
*/

app.use(Toast);
app.use(router);
app.use(VueTippy);

setDefaultProps({
  placement: right,
  delay: 100,
  theme: "light-border",
});

app.provide("translation", translation);
app.provide("translation_en", translation_en);
app.provide("translation_de", translation_de);
app.provide("socketTracking", socketTracking);
app.provide("socketChat", socketChat);

app.mount("#app");
