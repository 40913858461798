<template>
  <header v-if="canPost">
    <div class="user-image">
      <img :src="imageURL" v-if="hasImage" loading="lazy" />
      <span v-else class="user-image"></span>
    </div>
    <div class="field has-icon-right">
      <input
        class="input is-filled"
        :placeholder="translation.create_thread.new_post_placeholder"
        v-model="newThreadName"
        :class="{ 'is-danger': errorObject.newThreadName }"
        @click="posting = true"
      />
      <span class="icon is-right" v-if="errorObject.newThreadName">
        <i class="mdi mdi-alert" />
      </span>
      <span
        class="icon is-right is-clickable"
        style="width: auto; height: auto"
        v-if="posting"
        @click="clearValues()"
      >
        <i class="mdi mdi-close" />
      </span>
    </div>

    <div v-if="posting" style="line-height: 15px" class="field has-icon-right">
      <select
        v-model="newThreadForum"
        class="input is-filled"
        :class="{ 'is-danger': errorObject.newThreadForum }"
        :disabled="forums.length === 1"
        required
      >
        <option v-if="forums.length > 1" value="" disabled selected hidden>
          {{ translation.create_thread.select_forum_placeholder }}
        </option>
        <option
          v-for="forum in forums.filter((element) => {
            return element.status !== 'locked';
          })"
          :key="forum.id"
          :value="forum.id"
          style="color: black"
          :disabled="!forum.permissions.includes('create_threads')"
        >
          {{ forum.name }}
        </option>
      </select>
      <span class="icon is-right" v-if="errorObject.newThreadForum">
        <i class="mdi mdi-alert" />
      </span>
      <span class="icon is-right">
        <i class="mdi mdi-chevron-down" />
      </span>
    </div>
    <div v-if="posting" class="field mt-2" style="line-height: 15px">
      <editor
        api-key="yp0m3cpsawjia95l13xuhnel1zym1gw3ykqj5ptfhh5fh53q"
        :init="{
          height: 250,
          menubar: false,
          plugins: [
            'advlist',
            'autolink',
            'lists',
            'link',
            'image',
            'charmap',
            'print',
            'preview',
            'anchor',
            'searchreplace',
            'visualblocks',
            'code',
            'fullscreen',
            'emoticons',
            'insertdatetime',
            'media',
            'table',
            'paste',
            'code',
            'help',
            'wordcount',
          ],
          toolbar:
            'undo redo | bold italic underline strikethrough | \
   bullist numlist outdent indent ',
          content_style:
            'div {font-family: Arial, sans-serif} p {margin: 0px; padding: 0px} h1 {font-size: 2em; font-weight: 50} p {margin-block-start: 0em; margin-block-end: 0em;}',
        }"
        v-model="newThreadContent"
        class="input"
      />
    </div>

    <div v-if="posting" class="enter-button has-text-centered mt-2 mb-2">
      <button
        class="button is-main"
        @click="createThread()"
        :class="{ 'is-loading': loading }"
      >
        <i class="mdi mdi-send mr-2" />
        {{ translation.create_thread.buttons.create }}
      </button>
    </div>
  </header>
</template>

<script setup>
import Editor from "@tinymce/tinymce-vue";
import { useToast } from "vue-toastification";

import { ref, watchEffect, defineProps, defineEmits, computed } from "vue";

import CreateThread from "@/helpers/community/thread/create";

const toast = useToast();

const emit = defineEmits(["reload"]);

const props = defineProps({
  organization: Object,
  forums: Array,
  authObject: Object,
  translation: Object,
});

const canPost = computed(() => {
  if (props.forums) {
    return props.forums.some((forum) => {
      return forum.permissions.includes("create_threads");
    });
  }
  return false;
});

const newThreadName = ref("");
const newThreadForum = ref("");
const newThreadContent = ref("");
const errorObject = ref({});

watchEffect(() => {
  if (props.forums.length === 1) {
    newThreadForum.value = props.forums[0].id;
  }
});

async function createThread() {
  loading.value = true;
  errorObject.value = {};

  if (newThreadName.value === null || newThreadName.value === "") {
    errorObject.value.newThreadName = true;
  }

  if (newThreadForum.value === null || newThreadForum.value === "") {
    errorObject.value.newThreadForum = true;
  }

  if (Object.keys(errorObject.value).length === 0) {
    const thread_response = await CreateThread(
      props.organization.id,
      newThreadForum.value,
      { name: newThreadName.value, content: newThreadContent.value }
    );

    if (thread_response.success) {
      clearValues();
      emit("reload");
    } else {
      // Error Handling
      let errorMessage = props.translation.errors.other;
      switch (thread_response.errorid) {
        case "errors_in_request":
          for (let error of thread_response.errors) {
            switch (error.param) {
              case "name":
                errorObject.value.newThreadName = true;
                break;
              case "content":
                errorObject.value.newThreadContent = true;
                break;
            }
          }
          errorMessage = props.translation.errors[thread_response.errorid];
          break;
        case "no_access":
          errorMessage = props.translation.errors[thread_response.errorid];
          break;
        case "no_user_given":
        case "connection_error":
        case "database_error":
        default:
          break;
      }
      toast.error(errorMessage);
    }
  } else {
    toast.error(props.translation.errors.errors_in_request);
  }

  loading.value = false;
}

/**
 * HELPERS
 */

const posting = ref(false);
const loading = ref(false);

function clearValues() {
  newThreadName.value = "";
  newThreadForum.value = "";
  newThreadContent.value = "";
  errorObject.value = {};
  posting.value = false;
}

const random = ref(Math.random());
const hasImage = ref();
const imageURL = computed(() => {
  return (
    process.env.VUE_APP_API_MEDIA_URL +
    "user/" +
    props.organization.id +
    "/" +
    props.authObject.user_id +
    "/profile.jpg?" +
    random.value
  );
});

function imageExists(url) {
  addImageProcess(url)
    .then(() => {
      hasImage.value = true;
    })
    .catch((e) => {
      console.log("No image for user");
      e;
      hasImage.value = false;
    });
}

watchEffect(() => {
  if (props.authObject && props.authObject.user_id) {
    imageExists(imageURL.value);
  }
});

function addImageProcess(src) {
  return new Promise((resolve, reject) => {
    let img = new Image();
    img.onload = () => resolve(img.width);
    img.onerror = reject;
    img.src = src;
  });
}
</script>

<style>
.forum-content > header {
  overflow: hidden;
  display: grid;
  min-height: 2.5rem;
  grid-template-columns: repeat(12, minmax(0, 1fr));
}

.forum-content > header > * {
  justify-self: stretch;
}

.forum-content > header .field {
  grid-column-start: 2;
  grid-column-end: 13;
  align-self: center;
}

.forum-content > header .enter-button {
  grid-column: 1 / 13;
  justify-self: end;
}

.forum-content > header .field:not(:last-child) {
  margin-bottom: 0;
}
</style>
