import SecuredConnection from "../../general/SecuredConnection";

import * as DOMPurify from "dompurify";

export default async function CreatePost(
  organization,
  forum,
  thread,
  parent,
  data
) {
  let GetStatus = {
    success: true,
    errorid: null,
  };

  if (!organization || !forum || !thread || !data) {
    GetStatus.success = false;
    GetStatus.errorid = "no_data_frontend";
    return GetStatus;
  }

  if (data.content) {
    data.content = DOMPurify.sanitize(data.content, {
      USE_PROFILES: { html: true },
    });
  }

  let URL =
    process.env.VUE_APP_API_GATEWAY +
    process.env.VUE_APP_API_COMMUNITY +
    "post/create/" +
    organization +
    "/" +
    forum +
    "/" +
    thread;

  if (parent) {
    URL += "/" + parent;
  }

  const bearer = "Bearer " + localStorage.getItem("auth/jwt");

  const requestObject = {
    method: "POST",
    mode: "cors",
    credentials: "include",
    headers: {
      Authorization: bearer,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  };

  const response = await SecuredConnection(URL, requestObject);

  if (!response) {
    GetStatus.success = false;
    GetStatus.errorId = "response_error";
    return GetStatus;
  } else if (response.status === 200) {
    const returnObject = await response.json();
    return returnObject;
  } else {
    const returnObject = await response.json();
    GetStatus.success = false;
    GetStatus.errorid = returnObject.errorid;
    if (returnObject.errors) {
      GetStatus.errors = returnObject.errors;
    }
    return GetStatus;
  }
}
