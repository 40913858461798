import initializeEnglishTranslation from "./en/initializeEnglishTranslation"
import initializeGermanTranslation from "./de/initializeGermanTranslation"

export default function initializeTranslation(language) {
    let translation = {};

    if (!language) {
        language = "en";
    }

    switch (language) {
        case "en": {
            translation = initializeEnglishTranslation();
            break;
        }
        case "de": {
            translation = initializeGermanTranslation();
            break;
        }
        default: {
            translation = initializeEnglishTranslation();
        }
    }

    return translation;
}