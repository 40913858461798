<template>
  <nav
    class="forum-navbar p-3"
    :style="{
      height: height,
    }"
  >
    <!-- Forums -->
    <aside class="menu">
      <p class="menu-label">{{ translation.sidebar.title }}</p>
      <ul class="menu-list">
        <li v-for="forum in forums" :key="forum.id">
          <a @click="goToForum(forum.shortlink)"
            >{{ forum.name }}
            <span v-if="forum.status === 'locked'">
              <i class="mdi mdi-lock" /> </span
          ></a>
        </li>
      </ul>
    </aside>
  </nav>
</template>

<script setup>
import { ref, defineProps, watchEffect, defineEmits } from "vue";

import GetAllForums from "@/helpers/community/forum/getAll";

const emit = defineEmits(["go-to-forum"]);

const props = defineProps({
  organization: Object,
  height: String,
  translation: Object,
});

const forums = ref([]);

async function loadData() {
  forums.value = [];
  const forum_response = await GetAllForums(props.organization.id);

  if (forum_response.success) {
    forums.value = forum_response.returns.data;
  }
}

watchEffect(() => {
  if (props.organization.id) {
    loadData();
  }
});

/**
 * ROUTER HANDLING
 */
function goToForum(shortlink) {
  emit("go-to-forum", shortlink);
}
</script>

<style>
.menu-list {
  padding: 0;
  margin: 0;
}

.menu-label {
  color: inherit;
}

.menu-list li {
  list-style-type: none;
}

.menu-list a,
.menu-list a:hover {
  color: inherit;
}
</style>
