import SecuredConnection from "../../general/SecuredConnection";

export default async function GetPostsByThread(organization, forum, thread) {
  let GetStatus = {
    success: true,
    errorid: null,
  };

  if (!organization || !forum || !thread) {
    GetStatus.success = false;
    GetStatus.errorid = "no_data_frontend";
    return GetStatus;
  }

  const URL =
    process.env.VUE_APP_API_GATEWAY +
    process.env.VUE_APP_API_COMMUNITY +
    "post/get/" +
    organization +
    "/" +
    forum +
    "/" +
    thread;

  const bearer = "Bearer " + localStorage.getItem("auth/jwt");

  const requestObject = {
    method: "GET",
    mode: "cors",
    credentials: "include",
    headers: {
      Authorization: bearer,
    },
  };

  const response = await SecuredConnection(URL, requestObject);

  if (!response) {
    GetStatus.success = false;
    GetStatus.errorId = "response_error";
    return GetStatus;
  } else if (response.status === 200) {
    const returnObject = await response.json();
    return returnObject;
  } else {
    const returnObject = await response.json();
    GetStatus.success = false;
    GetStatus.errorid = returnObject.errorid;
    return GetStatus;
  }
}
