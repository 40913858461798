import general from "./general/general.json";

import forum from "./community/forum.json";
// 121 Meetings
import meetings from "./meetings/meetings.json";

export default function initializeGermanTranslation() {
  let germanTranslation = {
    general,
    community: { forum },
    meetings: { meetings },
  };

  return germanTranslation;
}
