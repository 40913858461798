import RefreshToken from "@/helpers/authentication/RefreshToken";

export default async function SecuredConnection(apiCallUrl, requestObject) {
  const response = await fetch(apiCallUrl, requestObject).catch((e) => {
    console.error(e);
  });

  if (response) {
    if (response.status === 401 || response.status === 403) {
      // const now = new Date().getTime();

      const token = await RefreshToken();

      if (token) {
        await localStorage.setItem("auth/jwt", token);
        const bearer = "Bearer " + token;

        requestObject.headers.Authorization = bearer;

        const responseSecondTry = await fetch(apiCallUrl, requestObject).catch(
          (e) => {
            console.error(e);
          }
        );

        if (
          responseSecondTry.status === 401 ||
          responseSecondTry.status === 403
        ) {
          localStorage.setItem("auth/jwt", "");
          return false;
        }

        return responseSecondTry;
      } else {
        localStorage.setItem("auth/jwt", "");
        return false;
      }
    } else {
      if (response.status !== 404 && response.status !== 504) {
        return response;
      } else {
        return false;
      }
    }
  } else {
    return false;
  }
}
